"use client";

import { useState, useEffect } from "react";
import { Editor as NovelEditor } from "novel";
import { toPng } from 'html-to-image';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircularProgress from '@mui/material/CircularProgress';
import ExportIcon from '@mui/icons-material/SaveAlt';
import logger from "@/lib/logger";
import { getFirebaseAnalytics } from '@/lib/hooks/firebase';
import { logEvent } from 'firebase/analytics';
import { toast } from "sonner";
import { MoreHorizontalIcon } from "lucide-react";

export default function Editor({ title, isReorderEnabled, timeRange, sourceType, sourceId, sourceIndex, noteContent, ...props }) {
  const [editorContent, setEditorContent] = useState(null); // State to hold the editor's content
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const [editorStatus, setEditorStatus] = useState("Saved");
  const [isExporting, setIsExporting] = useState(false); // State to track export status
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for menu anchor

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (noteContent instanceof Promise) {
      setIsLoading(true);
      noteContent.then(content => {
        setEditorContent(content);
        setIsLoading(false);
      }).catch(error => {
        logger.error('Error loading editor content', error);
        toast.error('Error loading editor content');
        setIsLoading(false);
      });
    } else {
      // Handle case where defaultValue is not a Promise
      setEditorContent(noteContent);
      setIsLoading(false);
    }
  }, [noteContent]);

  // Destructure the onDebouncedUpdate from props and pass the rest to NovelEditor
  const { onDebouncedUpdate, ...novelEditorProps } = props;

  // Combine custom onDebouncedUpdate with the one from props, if provided
  const combinedOnDebouncedUpdate = (editor) => {
    const updatedContent = editor.getJSON();

    // Call the external onDebouncedUpdate function if it exists
    if (onDebouncedUpdate) {
      onDebouncedUpdate(updatedContent, sourceType, sourceId, sourceIndex);
    }

    // Log event for successful auto-save
    logEvent(getFirebaseAnalytics(), 'auto_save_successful', {
      content_length: updatedContent.length,
      sourceType
    });

    logger.debug('Editor content saved', updatedContent);
  };

  useEffect(() => {
    let timeoutId;
    if (editorStatus === "Saving...") {
      timeoutId = setTimeout(() => {
        setEditorStatus("Saved");
      }, 500);
    }
    return () => clearTimeout(timeoutId);
  }, [editorStatus]);

  const handleExportAsImage = async () => {
    setIsExporting(true);
    handleClose();
    try {
      const editorElement = document.querySelector('#editor-container') as HTMLElement;
  
      if (!editorElement) {
        throw new Error("Editor element not found.");
      }
  
      // Wait for all images to load
      const images = Array.from(editorElement.querySelectorAll('img'));
      await Promise.all(images.map(img => {
        return new Promise((resolve, reject) => {
          if (img.complete) {
            resolve(true);
          } else {
            img.onload = () => resolve(true);
            img.onerror = () => reject(new Error('Image failed to load'));
          }
        });
      }));
  
      // Custom filter function
      const filter = (node: Node) => {
        if (!(node instanceof HTMLElement)) {
          return true;
        }
  
        if (node.classList.contains('screenshot-show')) {
          return true;
        }
  
        // Exclude buttons and elements with the 'screenshot-hide' class
        if (node.tagName === 'BUTTON' || node.classList.contains('screenshot-hide')) {
          return false;
        }
        return true;
      };
  
      // Generate Image from Html
      const dataUrl = await toPng(editorElement, {
        cacheBust: true,
        filter: filter
      });
  
      // Replace special characters in the title with underscores
      const sanitizedTitle = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  
      // Create a download link and click it to save the image
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${sanitizedTitle}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      toast.success('Image exported successfully');
    } catch (error) {
      logger.error('Error exporting as image', error);
      toast.error('Error exporting as image');
    } finally {
      setIsExporting(false);
    }
  };


  if (isLoading) {
    return <div>Loading editor...</div>;
  }

  return (
    <div className="relative min-h-full w-full min-w-full max-w-screen-lg novel-bg-white" id="editor-container">
      <div className="screenshot-hide absolute right-2 top-5 z-10 flex items-center space-x-1">
        <div className="rounded-lg bg-stone-100 px-2 py-1 text-sm text-stone-400">
          {editorStatus}
        </div>
        {isExporting ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Tooltip title="More options">
              <IconButton onClick={handleClick} className="text-stone-500">
                <MoreHorizontalIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleExportAsImage}>
                <ListItemIcon>
                  <ExportIcon fontSize="small" />
                </ListItemIcon>
                Export as Image
              </MenuItem>
              {/* Add more menu items here for future options */}
            </Menu>
          </>
        )}
      </div>
      <NovelEditor
        {...novelEditorProps}
        defaultValue={editorContent}
        editorTitle={title}
        isReorderEnabled={isReorderEnabled}
        editorTimeRange={timeRange}
        onDebouncedUpdate={(editor) => {
          setEditorStatus("Saving...");
          combinedOnDebouncedUpdate(editor);
        }}
      />
    </div>
  );
}
